// Import all stylesheets
import '../stylesheets/application.css'

// Set up turbolinks
import Turbolinks from 'turbolinks'
Turbolinks.start()

// Set up @rails/ujs
import Rails from '@rails/ujs'
Rails.start()

// Load stimulus controllers
import '../controllers'
