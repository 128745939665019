// const tailSelect = require('tail.select.js')
import tailSelect from 'tail.select.js'

import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = [
    'dimensionsList',
    'dimensionsButton',
    'dimensionsSelect',
    'dimensionId',
    'dimensionName',
    'dimensionDescription',
    'productsImportButton',
    'productsList',
    'productsButton',
    'productsSelect',
    'sortableProducts',
  ]

  connect() {
    console.log('RelationsPickerController connected')
    this.sortable = Sortable.create(this.sortableProductsTarget, {
      animation: 200,
      draggable: '.product-item',
      dragClass: 'product-item-dragging',
    })
  }

  loadDimensions() {
    this.fetchRelation('dimensions')
      .then((html) => {
        this.dimensionsListTarget.innerHTML = html
      })
      .then(() => {
        let select = this.dimensionsListTarget.querySelector('select')
        activateSelect(select, this.dimensionIdTarget.value, (choice) => {
          this.selectDimension(convertChoice(choice))
        })
      })
  }

  selectDimension(dimension) {
    if (dimension.id != this.dimensionIdTarget.value) {
      for (let key in dimension)
        this[`dimension${capitalize(key)}Target`].value = dimension[key]
      this.loadProducts()
      this.clearProducts()
    }
  }

  clearProducts() {
    this.sortableProductsTarget.innerHTML = ''
  }

  loadProducts() {
    this.fetchRelation('production_items')
      .then((html) => {
        this.productsListTarget.innerHTML = html
      })
      .then(() => {
        let select = this.productsListTarget.querySelector('select')
        activateSelect(select, this.productIds(), (choice) => {
          this.selectProduct(convertChoice(choice), choice.selected)
        })
      })
  }

  selectProduct(product, selected) {
    let node,
      parent = this.sortableProductsTarget
    if (selected) {
      node = createProduct(product)
      parent.appendChild(node)
    } else if ((node = parent.querySelector(`[data-id="${product.id}"]`))) {
      node.parentNode.removeChild(node)
    }
  }

  importProducts() {
    let ids = prompt('Enter production item ids as a comma-separated list:')

    if (ids) {
      let select = getSelectInstance(this.productsSelectTarget)
      ids = ids.replace(/\s+?/g, '').split(',')
      this.clearProducts()

      Array.prototype.slice
        .call(this.productsSelectTarget.options)
        .forEach((option) => {
          option.selected = ids.indexOf(option.value) > -1
          this.selectProduct(
            convertChoice(select.options.get(option)),
            option.selected,
          )
        })

      tailSelect(this.productsSelectTarget).reload()
      this.sortable.sort(ids, true)
    }
  }

  productIds() {
    let nodes =
      this.sortableProductsTarget.querySelectorAll(':scope > [data-id]')
    return Array.prototype.slice
      .call(nodes)
      .map((node) => node.getAttribute('data-id'))
  }

  disableTargets() {
    this.toggleTargets(false)
  }

  enableTargets() {
    this.toggleTargets(true)
  }

  toggleTargets(enabled) {
    let buttons = [].concat(
      this.dimensionsButtonTargets,
      this.productsButtonTargets,
    )
    let selects = [].concat(
      this.dimensionsSelectTargets,
      this.productsSelectTargets,
    )
    buttons.forEach((button) => {
      button.disabled = !enabled
    })
    selects.forEach((select) => {
      getSelectInstance(select)[`${enabled ? 'en' : 'dis'}able`]()
    })
  }

  fetchRelation(relation) {
    let data = {
      source: this.data.get('source'),
      relation: relation,
      'options[dimension_id]': this.dimensionIdTarget.value,
    }

    this.disableTargets()

    return fetch(this.data.get('url'), {
      method: 'POST',
      credentials: 'same-origin',
      body: new URLSearchParams(data),
      headers: { 'X-CSRF-Token': getMetaValue('csrf-token') },
    }).then((response) => {
      this.enableTargets()
      return response.text()
    })
  }
}

//////////////////////////////
// Helpers ///////////////////
//////////////////////////////

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute('content')
}

function activateSelect(select, value, onChange) {
  if (Array.isArray(value)) {
    Array.prototype.slice.call(select.options).forEach((option) => {
      option.selected = value.indexOf(option.value) > -1
    })
  } else {
    select.value = value
  }

  return tailSelect(select, {
    search: true,
    descriptions: true,
    searchConfig: 'any',
  }).on('change', onChange)
}

function getSelectInstance(select) {
  let id = select.getAttribute('data-tail-select')
  return tailSelect.inst[id]
}

function convertChoice(choice) {
  return {
    id: choice.key,
    name: choice.value,
    description: choice.description,
  }
}

function createProduct(product) {
  let temp, node, key, item
  temp = document.getElementById('product_template')
  node = temp.content.querySelector('.product-item')
  node.setAttribute('data-id', product.id)
  for (key in product) {
    if ((item = node.querySelector(`[data-value=${key}]`)))
      item.value = product[key]
    if ((item = node.querySelector(`[data-inner-html=${key}]`)))
      item.innerHTML = product[key]
  }
  return document.importNode(node, true)
}

function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1)
}
