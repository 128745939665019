import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    // if (this.data.get('imageNotLoaded')) {
    // }
  }
}
