import BaseFormController from './base_form_controller'

export default class extends BaseFormController {
  static targets = ['productSetSelect', 'productList']

  connect() {
    super.connect()
    this.loadActivationList()
  }

  loadActivationList() {
    let productSetId = this.productSetSelectTarget.value

    if (!!productSetId) {
      this.productListTarget.innerHTML = this.data.get('loading-label')

      let activationPath = this.data
        .get('activation-path')
        .replace(':id', productSetId)

      return fetch(activationPath, { method: 'GET' })
        .then((response) => {
          return response.text()
        })
        .then((html) => {
          this.productListTarget.innerHTML = html
        })
    } else {
      this.productListTarget.innerHTML = this.data.get('nothing-label')
    }
  }
}
